import { autoinject } from "aurelia-framework";
import { NavigationInstruction } from "aurelia-router";

// singleton by virtue of aurelia's default DI
@autoinject
export class NotAuthorizedRedirect {
  private navigationInstruction: NavigationInstruction | null;

  public saveNextRoute(from: NavigationInstruction) {
    this.navigationInstruction = from;
  }

  public tryReturnTo(): string {
    if (!this.navigationInstruction) return "";

    const routeName = this.navigationInstruction.config.name;
    if (routeName === undefined || routeName == "userLogin") return "";

    const uri = this.navigationInstruction.router.generate(
      routeName,
      Object.assign(this.navigationInstruction.params, this.navigationInstruction.queryParams),
      { replace: true },
    );

    this.navigationInstruction = null;
    return uri;
  }
}
