import { Router } from "aurelia-router";
import { AppConfig } from "app-config";
import { Interceptor } from "aurelia-fetch-client";
import { autoinject } from "aurelia-framework";
import { TKError } from "utils/enums/TKError";

@autoinject
export class AuthInterceptor implements Interceptor {
  constructor(
    private appConfig: AppConfig,
    private router: Router,
  ) {}

  request(request: Request) {
    if (this.appConfig.isSessionExpired()) this.logout(true);

    return request;
  }

  response(response: Response) {
    if (response.status == 401) this.logout(true);

    return response;
  }

  logout(sessionExpired?: boolean): void {
    this.appConfig.removeToken();

    if (sessionExpired) {
      console.log("Session expired, AuthInterceptor logging out");
      this.router.navigateToRoute("userLogin", {
        error: TKError.sessionExpired,
      });
    } else {
      console.log("AuthInterceptor logging out");
      this.router.navigateToRoute("userLogin");
      location.reload();
    }
  }
}
