import { IResult } from "domain/IResult";
import { IInvoice } from "domain/Sale/IInvoice";
import { AppConfig } from "app-config";
import { LogManager, autoinject } from "aurelia-framework";
import { HttpClient } from "aurelia-fetch-client";
import { BaseService } from "../base-service";
import { AuthInterceptor } from "utils/classes/AuthInterceptor";

export const log = LogManager.getLogger("app.service.invoice");

@autoinject
export class InvoiceService extends BaseService {
  constructor(
    private httpClient: HttpClient,
    private appConfig: AppConfig,
    private authInterceptor: AuthInterceptor,
    private endPoint = "Invoice",
  ) {
    super(httpClient, appConfig, endPoint, authInterceptor);
  }

  fetchInvoices(
    page: number,
    query: string,
    dateFrom: string,
    dateTo: string,
  ): Promise<IResult<IInvoice>> {
    const itemsInPage = this.appConfig.userSettings.itemsInInvoicePage;

    const baseQuery = `/List/${page}/${itemsInPage}`;
    const parameters = `?query=${query}&dateFrom=${dateFrom}&dateTo=${dateTo}`;

    return super.fetch<IResult<IInvoice>>(baseQuery + parameters);
  }

  fetchInvoiceAsHtml(id: number): Promise<string> {
    return super.fetch<string>(`/${id}/Html`);
  }

  fetchInvoiceAsXml(id: number): Promise<string> {
    return super.fetch<string>(`/${id}/Xml`);
  }

  fetchInvoiceAsPdf(id: number): Promise<string> {
    return super.fetch<string>(`/${id}/Pdf/Base64`);
  }
}
