import { AuthInterceptor } from "utils/classes/AuthInterceptor";
import { BaseService } from "../base-service";
import { LogManager, autoinject } from "aurelia-framework";
import { HttpClient } from "aurelia-fetch-client";
import { AppConfig } from "app-config";
import { IPerson } from "domain/Client/IPerson";

export const log = LogManager.getLogger("app.service.client");

@autoinject
export class ContractService extends BaseService {
  constructor(
    private httpClient: HttpClient,
    private appConfig: AppConfig,
    private authInterceptor: AuthInterceptor,
    private endPoint = "Contract",
  ) {
    super(httpClient, appConfig, endPoint, authInterceptor);
  }

  getClientManager(): Promise<IPerson> {
    return super.fetch<IPerson>("/ClientManager");
  }
}
