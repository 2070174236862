import { IProduct } from "domain/Product/IProduct";
import { IResult } from "../../domain/IResult";
import { IParentItem } from "domain/Base/IParentItem";
import { BaseService } from "../base-service";
import { AppConfig } from "app-config";
import { LogManager, autoinject } from "aurelia-framework";
import { HttpClient } from "aurelia-fetch-client";
import { SearchOrder } from "utils/enums/SearchOrder";
import { AuthInterceptor } from "utils/classes/AuthInterceptor";

export const log = LogManager.getLogger("app.service.catalogue");

@autoinject
export class CatalogueService extends BaseService {
  constructor(
    private httpClient: HttpClient,
    private appConfig: AppConfig,
    private authInterceptor: AuthInterceptor,
    private endPoint = "Catalogue",
  ) {
    super(httpClient, appConfig, endPoint, authInterceptor);
  }

  fetchCatalogues(vehicleId: number): Promise<IParentItem[]> {
    return super.fetchAll<IParentItem>(`/${vehicleId}`);
  }

  fetchProducts(
    vehicleId: number,
    catalogueId: number,
    page = 1,
    orderBy: SearchOrder,
    orderReversed: boolean,
  ): Promise<IResult<IProduct>> {
    const itemsInPage = this.appConfig.userSettings.itemsInProductPage;
    return super.fetch<IResult<IProduct>>(
      `/${vehicleId}/${catalogueId}/${page}/${itemsInPage}/${orderBy}/${orderReversed}`,
    );
  }
}
