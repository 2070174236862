import { AuthInterceptor } from "utils/classes/AuthInterceptor";
import { IResult } from "domain/IResult";
import { IProduct } from "domain/Product/IProduct";
import { BaseService } from "../base-service";
import { AppConfig } from "app-config";
import { LogManager, autoinject } from "aurelia-framework";
import { HttpClient } from "aurelia-fetch-client";
import { IManufacturer } from "domain/Car/IManufacturer";
import { IBaseVehicle } from "domain/Car/IBaseVehicle";
import { IModel } from "domain/Car/IModel";
import { SearchOrder } from "utils/enums/SearchOrder";
import { Utils } from "utils/helpers/utils";

export const log = LogManager.getLogger("app.service.car-category");

@autoinject
export class CarCategoryService extends BaseService {
  constructor(
    private httpClient: HttpClient,
    private appConfig: AppConfig,
    private authInterceptor: AuthInterceptor,
    private endPoint = "CarCategory",
  ) {
    super(httpClient, appConfig, endPoint, authInterceptor);
  }

  fetchManufacturers(categoryId: number): Promise<IManufacturer[]> {
    return super.fetchAll<IManufacturer>(`/Manufacturers/${categoryId}`);
  }

  fetchModels(categoryId: number, manufacturerId: number): Promise<IModel[]> {
    return super.fetchAll<IModel>(`/Models/${categoryId}/${manufacturerId}`);
  }

  fetchVehicles(
    categoryId: number,
    manufacturerId: number,
    modelName: string,
  ): Promise<IBaseVehicle[]> {
    return super.fetchAll<IBaseVehicle>(`/Vehicles/${categoryId}/${manufacturerId}/${modelName}`);
  }

  fetchProducts(
    categoryId: number,
    vehicleId: number,
    page = 1,
    orderBy: SearchOrder,
    orderReversed: boolean,
    filters?: object,
  ): Promise<IResult<IProduct>> {
    const itemsInPage = this.appConfig.userSettings.itemsInProductPage;

    let query = "";
    if (filters) {
      query += "?" + Utils.getFilterQuery(filters);
    }

    return super.fetch<IResult<IProduct>>(
      `/Products/${categoryId}/${vehicleId}/${page}/${itemsInPage}/${orderBy}/${orderReversed}${query}`,
    );
  }

  fetchVehicle(categoryId: number, vehicleId: number): Promise<IBaseVehicle> {
    return super.fetch<IBaseVehicle>(`/Vehicle/${categoryId}/${vehicleId}`);
  }
}
