import { AuthInterceptor } from "utils/classes/AuthInterceptor";
import { ICar } from "domain/Car/ICar";
import { BaseService } from "../base-service";
import { IVehicle } from "domain/Car/IVehicle";
import { IModel } from "domain/Car/IModel";
import { AppConfig } from "app-config";
import { LogManager, autoinject } from "aurelia-framework";
import { HttpClient } from "aurelia-fetch-client";
import { IManufacturer } from "domain/Car/IManufacturer";

export const log = LogManager.getLogger("app.service.car");

@autoinject
export class CarService extends BaseService {
  constructor(
    private httpClient: HttpClient,
    private appConfig: AppConfig,
    private authInterceptor: AuthInterceptor,
    private endPoint = "Car",
  ) {
    super(httpClient, appConfig, endPoint, authInterceptor);
  }

  fetchManufacturers(): Promise<IManufacturer[]> {
    return super.fetchAll<IManufacturer>("/Manufacturers");
  }

  fetchModels(manufacturerId: number): Promise<IModel[]> {
    return super.fetchAll<IModel>(`/Models/${manufacturerId}`);
  }

  fetchVehicles(manufacturerId: number, modelId: number): Promise<IVehicle[]> {
    return super.fetchAll<IVehicle>(`/Vehicles/${manufacturerId}/${modelId}`);
  }

  fetchVehiclesByQuery(query: string): Promise<IVehicle[]> {
    return super.fetchAll<IVehicle>(`/Vehicles/${query}`);
  }

  fetchCar(vehicleId: number): Promise<ICar> {
    return super.fetch<ICar>(`/Car/${vehicleId}`);
  }

  fetchCarByQuery(query: string): Promise<ICar> {
    return super.fetch<ICar>(`/Car/Mnt/${query}`);
  }

  fetchCarWithQuery(vehicleId: number, query: string): Promise<ICar> {
    return super.fetch<ICar>(`/Car/${vehicleId}/${query}`);
  }
}
