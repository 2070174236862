import { AuthInterceptor } from "utils/classes/AuthInterceptor";
import { IParentItem } from "domain/Base/IParentItem";
import { BaseService } from "../base-service";
import { AppConfig } from "app-config";
import { LogManager, autoinject } from "aurelia-framework";
import { HttpClient } from "aurelia-fetch-client";
import { IStockItem } from "domain/Product/IStockItem";

export const log = LogManager.getLogger("app.service.product");

@autoinject
export class ProductService extends BaseService {
  constructor(
    private httpClient: HttpClient,
    private appConfig: AppConfig,
    private authInterceptor: AuthInterceptor,
    private endPoint = "Product",
  ) {
    super(httpClient, appConfig, endPoint, authInterceptor);
  }

  fetchThumbnail(id: number): Promise<string> {
    return super.fetch<string>(`/${id}/Thumbnail`);
  }

  fetchImages(id: number): Promise<string[]> {
    return super.fetchAll<string>(`/${id}/Images`);
  }

  fetchImagesWithCategory(id: number, categoryId: number): Promise<string[]> {
    return super.fetchAll<string>(`/${id}/Images/${categoryId}`);
  }

  fetchInfo(id: number): Promise<[string, string][]> {
    return super.fetch<[string, string][]>(`/${id}/Info`);
  }

  fetchInfoWithCar(id: number, vehicleId: number): Promise<[string, string][]> {
    return super.fetch<[string, string][]>(`/${id}/Info/${vehicleId}`);
  }

  fetchInfoWithCategory(
    id: number,
    vehicleId: number,
    categoryId: number,
  ): Promise<[string, string][]> {
    return super.fetch<[string, string][]>(`/${id}/Info/${vehicleId}/Category/${categoryId}`);
  }

  fetchDescription(id: number): Promise<string> {
    return super.fetch<string>(`/${id}/Description`);
  }

  fetchDescriptionWithCategory(id: number, categoryId: number): Promise<string> {
    return super.fetch<string>(`/${id}/Description/Category/${categoryId}`);
  }

  fetchDocuments(id: number): Promise<[string, string][]> {
    return super.fetchAll<[string, string]>(`/${id}/Documents`);
  }

  fetchDocumentsWithCategory(id: number, categoryId: number): Promise<[string, string][]> {
    return super.fetchAll<[string, string]>(`/${id}/Documents/Category/${categoryId}`);
  }

  fetchOeNumbers(id: number): Promise<object> {
    return super.fetch<object>(`/${id}/OeNumbers`);
  }

  fetchExternalStock(id: number): Promise<IStockItem[]> {
    return super.fetchAll<IStockItem>(`/${id}/ExternalStock`);
  }

  fetchCars(id: number): Promise<IParentItem[]> {
    return super.fetchAll<IParentItem>(`/${id}/Cars`);
  }

  postReportError(productCode: string, reportText: string): Promise<any> {
    return super.post<any>(reportText, `/${productCode}/ReportError`);
  }

  postProductStockRequest(productCode: string): Promise<any> {
    return super.post<any>(null, `/${productCode}/PleaseStockThis`);
  }
}
