import { LogManager } from "aurelia-framework";

export const log = LogManager.getLogger("app.utils.helpers.car");

export class CarHelper {
    // store variables to be used in other components
    public static carPlateRegex =
        /^([\d]{4}[A-Z]{2}|[\d]{3}[A-Z]{3}|[\d]{3}[A-Z]{1}|[\d]{2}[A-Z]{2,3}|[A-Z]{1,8}[\d]{1,8})$/i;
    public static standardCarPlateRegex = /^([\d]{3}[A-Z]{3})$/i;
    public static vinRegex = /^([\dA-Z]{17})$/gi;

  public static isValidTireString(tire: string): boolean {
    return /^.{0,3}(\d{3})\/?(\d{2})?\D{0,3}(\d{2})\D/.test(tire);
  }

  public static decodeTireString(tire: string): string[] {
    if (!CarHelper.isValidTireString(tire)) return [];

    const match = tire.match(/^.{0,3}(\d{3})\/?(\d{2})?\D{0,3}(\d{2})\D/)!;

    const width = match[1];
    const height = match[2];
    const diameter = match[3];

    return [width, height, diameter];
  }
}