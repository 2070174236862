import { IResult } from "domain/IResult";
import { IProduct } from "domain/Product/IProduct";
import { IParentItem } from "domain/Base/IParentItem";
import { BaseService } from "services/base-service";
import { AppConfig } from "app-config";
import { LogManager, autoinject } from "aurelia-framework";
import { HttpClient } from "aurelia-fetch-client";
import { SearchOrder } from "utils/enums/SearchOrder";
import { Utils } from "utils/helpers/utils";
import { AuthInterceptor } from "utils/classes/AuthInterceptor";

export const log = LogManager.getLogger("app.service.category");

@autoinject
export class CategoryService extends BaseService {
  constructor(
    private httpClient: HttpClient,
    private appConfig: AppConfig,
    private authInterceptor: AuthInterceptor,
    private endPoint = "Category",
  ) {
    super(httpClient, appConfig, endPoint, authInterceptor);
  }

  fetchCategories(): Promise<IParentItem[]> {
    return super.fetchAll<IParentItem>();
  }

  fetchFilters(categoryId: number, vehicleId: number | undefined): Promise<object> {
    const tail = vehicleId ? `/${vehicleId}` : "";
    return super.fetch<object>(`/Filters/${categoryId}${tail}`);
  }

  fetchProducts(
    categoryId: number,
    page = 1,
    orderBy: SearchOrder,
    orderReversed: boolean,
    filters?: object,
  ): Promise<IResult<IProduct>> {
    const itemsInPage = this.appConfig.userSettings.itemsInProductPage;

    let query = "";
    if (filters) {
      query += "?" + Utils.getFilterQuery(filters);
    }

    return super.fetch<IResult<IProduct>>(
      `/${categoryId}/${page}/${itemsInPage}/${orderBy}/${orderReversed}${query}`,
    );
  }
}
