import { IResult } from "domain/IResult";
import { ICart } from "domain/Cart/ICart";
import { AppConfig } from "app-config";
import { LogManager, autoinject } from "aurelia-framework";
import { HttpClient } from "aurelia-fetch-client";
import { BaseService } from "../base-service";
import { AuthInterceptor } from "utils/classes/AuthInterceptor";

export const log = LogManager.getLogger("app.service.order");

@autoinject
export class OrderService extends BaseService {
  constructor(
    private httpClient: HttpClient,
    private appConfig: AppConfig,
    private authInterceptor: AuthInterceptor,
    private endPoint = "Order",
  ) {
    super(httpClient, appConfig, endPoint, authInterceptor);
  }

  fetchOrders(
    page: number,
    query: string,
    dateFrom: string,
    dateTo: string,
  ): Promise<IResult<ICart>> {
    const itemsInPage = this.appConfig.userSettings.itemsInOrderPage;

    const baseQuery = `/List/${page}/${itemsInPage}`;
    const parameters = `?query=${query}&dateFrom=${dateFrom}&dateTo=${dateTo}`;

    return super.fetch<IResult<ICart>>(baseQuery + parameters);
  }

  convertToCart(id: number) {
    const url = `/${id}/ToCart`;
    return super.post("", url);
  }

  convertRvsOrderToCart(id: number) {
    const url = `/Rvs/${id}/ToCart`;
    return super.post("", url);
  }
}
